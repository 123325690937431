import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useGetMeQuery, useLoginMutation } from "redux/services/apiService";
import { useGetConfigsQuery } from "redux/services/config";
import { isUserAuthenticated } from "redux/slices/user";
import { useAppSelector } from "redux/store";

type Props = {};
const Login: React.FC<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(isUserAuthenticated);

  const [loginMutation] = useLoginMutation();
  const { data: config } = useGetConfigsQuery();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const toast = useToast();
  const { isLoading } = useGetMeQuery();
  const [show, setShow] = React.useState(false);
  // const navigate = useNavigate();
  const handleClick = () => setShow(!show);
  const onSubmit = async (values) => {
    try {
      await loginMutation({
        email: values.email,
        password: values.password,
      }).unwrap();

      toast({
        title: t("login_successfully"),
        isClosable: true,
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
    }
  };
  if (!isAuthenticated) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Stack flexDir="column" justifyContent="center" alignItems="center">
          {config?.data[0]?.site_logo ? (
            <Image
              src={
                process.env.REACT_APP_API_URL + "/" + config?.data[0]?.site_logo
              }
              w="20"
            />
          ) : null}
          <Text fontSize="5xl" fontWeight="bold" textAlign="center">
            Selamat Datang
          </Text>
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email">{t("email")}</FormLabel>
                <Input
                  placeholder="email@example.com"
                  {...register("email")}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={!!errors.password}>
                <FormLabel htmlFor="password">{t("password")}</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    {...register("password")}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? t("button_hide") : t("button_show")}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                colorScheme="brand"
                isLoading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {t("button_login")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
  return (
    <Skeleton isLoaded={!isLoading}>
      <Stack align="center">
        {config?.data[0]?.site_logo ? (
          <Image
            src={
              process.env.REACT_APP_API_URL + "/" + config?.data[0]?.site_logo
            }
            w="20"
          />
        ) : null}
        <Text fontSize="5xl" fontWeight="bold" textAlign="center">
          Selamat Datang
        </Text>
      </Stack>
    </Skeleton>
  );
};

export default Login;
