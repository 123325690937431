import React, { useEffect, useState } from "react";

type TableauProps = {
  containerId: string;
  url: string;
  width?: number | string;
  height?: number | string;
};
const Tableau: React.FC<TableauProps> = ({
  url,
  containerId,
  width = "100%",
  height = "100%",
}) => {
  const [viz, setViz] = useState(null);
  const initViz = () => {
    // @ts-ignore
    const { tableau } = window;
    if (tableau) {
      let vizDiv = document.getElementById(containerId);
      if (viz) {
        viz.dispose();
        setViz(null);
      }

      setViz(
        new tableau.Viz(vizDiv, url, {
          hideTabs: false,
          hideToolbar: true,
        })
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initViz, [containerId]);

  return (
    <div
      style={{
        height,
        width,
        display: "flex",
        justifyContent: "center",
      }}
      id={containerId}
    />
  );
};

export default Tableau;
