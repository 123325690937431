import React from 'react'
import { useParams } from 'react-router-dom';

import { Box, Skeleton, Text } from '@chakra-ui/react'

import { useGetMeQuery } from "redux/services/apiService";

const SubDashboard: React.FC = (): JSX.Element => {
  const param = useParams()
  const { isLoading } = useGetMeQuery();
  console.log(param)
  return (
    <Skeleton isLoaded={!isLoading}>
      <Box mt="4">
          <Text
            fontSize="5xl"
            fontWeight="bold"
            color="#C0392B"
            textAlign="center"
            mt="20">
            Coming Soon! {param.submenu}
          </Text>
      </Box>
    </Skeleton>
  )
}

export default SubDashboard