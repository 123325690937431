import { apiService } from "redux/services/apiService";
import { ResponseSingle } from "redux/services/types";

import { Config, PostConfig } from "./types";

const TYPE = "config";
const userApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getConfigs: build.query<any, void>({
      query: () => ({ url: "/config" }),
      providesTags: (result) => {
        if (result) {
          return [
            ...result.data.map(({ id }) => ({ type: TYPE, id })),
            { type: TYPE, id: "list" },
          ] as any;
        }

        return [{ type: TYPE, id: "list" }];
      },
    }),
    updateConfig: build.mutation<
      ResponseSingle<Config>,
      Partial<PostConfig> & Pick<Config, "id">
    >({
      query: ({ id, ...config }) => {
        const formData = new FormData();
        Object.keys(config).forEach((key) => {
          formData.append(key, config[key]);
        });

        return {
          url: `/config/${id}`,
          headers: {
            "Content-type": "multipart/form-data",
          },
          method: "post",
          data: formData,
        };
      },
      invalidatesTags: (result) => {
        if (result?.status === "success") {
          return [
            { type: TYPE, id: "list" },
            { type: TYPE, id: result?.data.id },
          ];
        }
        return [];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetConfigsQuery, useUpdateConfigMutation } = userApi;
