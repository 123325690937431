export const MENUS_DATALAKE = [
    {
        menu: "kominfo_ditops",
        submenu: [
            { name: 'bts_ipfr', header: ['teknologi', 'tahun', 'bts', 'created_at', 'updated_at'] },
            { name: 'data_ipfr', header: ['pita', 'range', 'moda', 'nama_operator', 'jenis', 'area', 'created_at', 'updated_at'] },
            { name: 'data_labuh', header: ['administrasi', 'tahun', 'hak_labuh', 'created_at', 'updated_at'] },
            { name: 'data_pnbp', header: ['jenis', 'tahun', 'realisasi', 'created_at', 'updated_at'] },
            { name: 'iar', header: ['provinsi', 'tahun', 'iar', 'created_at', 'updated_at'] },
            { name: 'ikrap', header: ['provinsi', 'tahun', 'ikrap', 'created_at', 'updated_at'] },
            { name: 'pengunjung_ppt', header: ['bulan', 'kategori', 'jk', 'pengguna', 'pos_bln', 'created_at', 'updated_at'] },
            { name: 'radio_dinas_maritim_penerbangan', header: ['ids', 'name', 'sub_name', 'tahun', 'stn', 'created_at', 'updated_at'] },
            { name: 'radio_komunikasi', header: ['ids', 'name', 'short', 'cat', 'created_at', 'updated_at'] },
            { name: 'radio_station_frekuensi', header: ['ids', 'name', 'sub_name', 'tahun', 'stn', 'created_at', 'updated_at'] },
            { name: 'radio_station_jenis_penggunaan_frekuensi', header: ['ids', 'name', 'sub_name', 'stn', 'created_at', 'updated_at'] },
            { name: 'radio_station_penggunaan_frekuensi', header: ['name', 'sub_name', 'provinsi', 'stn', 'created_at', 'updated_at'] },
            { name: 'radio_station_pita_frekuensi', header: ['idb', 'name', 'provinsi', 'stn', 'created_at', 'updated_at'] },
            { name: 'reor', header: ['kota', 'tahun', 'kategori', 'peserta', 'created_at', 'updated_at'] },
            { name: 'respon_call', header: ['bulan', 'kategori', 'jumlah_call', 'avg', 'created_at', 'updated_at'] },
            { name: 'sertifikasi_kecakapan', header: ['provinsi', 'tahun', 'kategori', 'peserta', 'created_at', 'updated_at'] },
            { name: 'sertifikat_reor', header: ['jenis', 'tahun', 'sertifikat', 'created_at', 'updated_at'] },
            { name: 'statistik_tiket', header: ['bulan', 'kategori', 'ticket', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_edupak",
        submenu: [
            { name: 'tbl_butir', header: ['id_butir', 'id_sub_unsur', 'nm_butir', 'id_satuan', 'angka_kredit', 'id_jabatan', 'id_jabfung', 'created_at', 'updated_at'] },
            { name: 'tbl_dupak', header: ['id_dupak', 'nip', 'periode', 'date_start', 'date_end', 'step', 'status', 'nama_file', 'date_upload', 'id_pangkat', 'date_kirim', 'nip_penilai1', 'nip_penilai2', 'nip_pleno', 'his_note', 'created_at', 'updated_at'] },
            { name: 'tbl_file_spmlk', header: ['id_file', 'id_dupak', 'id_unsur', 'filename', 'fileloc', 'created_at', 'updated_at'] },
            { name: 'tbl_satuan', header: ['kd_satuan', 'nm_satuan', 'created_at', 'updated_at'] },
            { name: 'tbl_sub_unsur', header: ['id_sub_unsur', 'id_unsur', 'nama_sub_unsur', 'id_jabfung', 'created_at', 'updated_at'] },
            { name: 'tbl_unsur', header: ['id_unsur', 'id_type', 'nm_unsur', 'created_at', 'updated_at'] },
            { name: 'tbl_user', header: ['id_user', 'nip', 'password', 'priv', 'nama', 'tempat_lahir', 'tanggal_lahir', 'alamat', 'agama', 'sex', 'status_pegawai', 'kartu_pegawai', 'id_pangkat', 'tmt_pangkat', 'id_jabatan', 'tmt_jabatan', 'unit_kerja', 'email', 'foto', 'pak_lama', 'sign', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_filing",
        submenu: [
            // {name: 'adm_assoc', header: ['ntc_id', 'adm']},
            // {name: 'all_aff_ntw', header: ['aff_rec_id', 'aff_ntc_id', 'coord_prov', 'agree_st', 'adm', 'ntwk_org', 'sat_name', 'long_nom', 'ntf_rsn', 'st_aff', 'f_cause', 'f_rec']},
            // {name: 'alloc_id', header: ['ntc_year', 'grp_id_last']},
            // {name: 'ant_type', header: ['pattern_id', 'f_ant_type', 'f_sub_type', 'emi_rcp', 'pattern', 'coefa', 'coefb', 'coefc', 'coefd', 'phi1', 'f_ant_new', 'apl_name', 'd_upd']},
            // {name: 'ap30b_ref_agg', header: ['grp_id_dn', 'grp_id_up', 'seq_pt', 'fred_band', 'c2i']},
            // {name: 'ap30b_ref_se', header: ['grp_id_a', 'grp_id_i', 'seq_pt', 'fred_band', 'emi_rcp', 'c2i', 'agree_st']},
            // {name: 'ap30b_tr_res', header: ['ntc_id', 'freq_band', 'ntc_id_a', 'plan_status_a', 'se_dn_tp_degr_max', 'se_dn_gp_degr_max', 'se_up_degr_max', 'agg_degr_max', 'pfd_exc_dn_max', 'pfd_exc_up_max', 'f_pfd_appl']},
            // {name: 'assgn', header: ['grp_id', 'seq_no', 'freq_sym', 'freq_assgn', 'freq_mhz', 'f_cmp_rec']},
            // {name: 'attch', header: ['ntc_id', 'attch_no', 'attch_type', 'file_name', 'text_info']},
            // {name: 'beam_tr', header: ['ant_diam', 'pattern_id', 'design_emi', 'grp_id', 'pbeam_name', 'beam_name', 'emi_rcp', 'ntc_id']},
            // {name: 'c_pfd', header: ['ntc_id', 'seq_no', 'freq_min', 'freq_max', 'pfd', 'bdwdth', 'ra_stn_type']},
            // {name: 'carrier_fr', header: ['grp_id', 'seq_emiss', 'seq_no', 'freq_carr']},
            // {name: 'cmr_grp_lnk', header: ['ntc_id', 'seq_cmr', 'grp_id']},
            // {name: 'cmr_history', header: ['ntc_id', 'itu_scraft_id', 'seq_no', 'reg_st', 'd_reg_st', 'rsn_susp', 'wic_no']},
            // {name: 'cmr_notice', header: ['ntc_id', 'itu_scraft_id', 'd_reg_st', 'reg_st', 'rsn_susp']},
            // {name: 'cmr_syst', header: ['ntc_id', 'seq_no', 'ntwk_name', 'lsp_name', 'vehicle', 'd_exe', 'd_deliv_fr', 'd_deliv_to', 'facility', 'mfct_name', 'nbr_sat', 'd_exe_m', 'd_deliv_fr_m', 'd_deliv_to_m']},
            { name: 'com_el', header: ['ntc_id', 'prov', 'plan_id', 'adm', 'ntwk_org', 'sat_name', 'long_nom', 'act_code', 'ntf_rsn', 'st_cur', 'd_rcv', 'wic_no', 'wic_part', 'ntc_type', 'adm_ref_id', 'tgt_ntc_id', 'stn_name', 'long_dec', 'lat_dec', 'ctry'] },
            // {name: 'coord_agree_ntw', header: ['ntc_id', 'coord_prov', 'adm', 'ntwk_org', 'sat_name', 'long_nom']},
            // {name: 'cost_recov', header: ['grp_id', 'seq_gpub', 'd_invoice', 'f_invoice']},
            // {name: 'diag_grp', header: ['grp_id', 'diag_type', 'diag_no', 'attch_no']},
            // {name: 'e_ant', header: ['ntc_id', 'emi_rcp', 'beam_name', 'act_code', 'beam_old', 'bmwdth', 'attch_e', 'attch_e_x', 'gain', 'pattern_id', 'pattern_id_x', 'ant_diam', 'dgso', 'attch_crdn', 'f_fdg_reqd', 'cmp_ntc_id', 'cmp_beam', 'f_cmp_str', 'f_cmp_rec']},
            // {name: 'e_ant_elev', header: ['ntc_id', 'azm', 'elev_ang', 'f_cmp_rec']},
            // {name: 'e_as_stn', header: ['grp_id', 'seq_no', 'e_as_id', 'stn_name', 'stn_type', 'long_dec', 'lat_dec', 'ant_alt', 'clim_zone', 'noise_t', 'gain', 'ant_diam', 'dgso', 'bmwdth', 'pattern_id', 'pattern_id_x', 'long_deg', 'long_ew', 'long_min', 'long_sec', 'lat_deg', 'lat_ns', 'lat_min', 'lat_sec', 'ctry', 'act_code', 'attch_e', 'attch_e_x', 'diag_e', 'diag_e_x', 'stn_old', 'rcp_type', 'pwr_max', 'bdwdth_aggr', 'f_trp_band', 'f_cmp_rec']},
            // {name: 'e_srvcls', header: ['grp_id', 'seq_e_as', 'seq_no', 'stn_cls', 'nat_srv']},
            { name: 'e_stn', header: ['ntc_id', 'stn_name', 'ctry', 'long_deg', 'long_ew', 'long_min', 'long_sec', 'lat_deg', 'lat_ns', 'lat_min', 'lat_sec', 'sat_name', 'long_nom', 'attch_hor', 'elev_min', 'elev_max', 'azm_fr', 'azm_to', 'ant_alt', 'f_active', 'long_dec', 'lat_dec', 'f_pfd_se'] },
            // {name: 'emiss', header: ['grp_id', 'seq_no', 'design_emi', 'pep_max', 'pwr_ds_max', 'pep_min', 'pwr_ds_min', 'c_to_n', 'pwr_ds_nbw', 'pulse_length', 'pulse_rep', 'f_emi_type', 'attch_pep', 'attch_mpd', 'attch_c2n', 'pwr_ds_nbc', 'f_cmp_rec']},
            // {name: 'ex_op_grp', header: ['grp_id', 'beamgrp_id']},
            // {name: 'fdg_ref', header: ['grp_id', 'seq_no', 'd_fdg_rev', 'd_type', 'fdg_prov']},
            // {name: 'freq', header: ['ntc_id', 'emi_rcp', 'beam_name', 'grp_id', 'seq_no', 'freq_sym', 'freq_assgn', 'freq_mhz', 'freq_min', 'freq_max', 'bdwdth', 'fdg_reg', 'd_prot_eff', 'wic_no', 'ntc_type']},
            // {name: 'geo', header: ['ntc_id', 'sat_name', 'long_nom', 'tol_east', 'tol_west', 'inclin_exc', 'f_active', 'f_off_axis', 'f_pfd_lim', 'f_pfd_sep', 'f_esim', 'long_orig']},
            // {name: 'gpub', header: ['grp_id', 'seq_no', 'pub_ref', 'pub_no', 'ssn_type', 'ssn_rev', 'ssn_rev_no', 'wic_no', 'd_wic']},
            // {name: 'grp', header: ['grp_id', 'ntc_id', 'emi_rcp', 'beam_name', 'noise_t', 'd_rcv', 'd_prot_eff', 'd_reg_limit', 'd_inuse', 'f_biu', 'fdg_reg', 'fdg_plan', 'fdg_tex', 'area_no', 'bdwdth', 'freq_min', 'freq_max', 'polar_type', 'polar_ang', 'wic_no', 'wic_part', 'd_wic', 'adm_resp', 'op_agcy', 'd_rcv_start', 'prov', 'plan_status', 'reg_op_fr', 'reg_op_to', 'f_ap30b_art6', 'f_cost_rec', 'sr_type', 'page_no', 'act_code', 'prd_valid', 'remark', 'tgt_grp_id', 'pwr_max', 'bdwdth_aggr', 'f_trp_band', 'observ_cls', 'd_upd', 'st_cur', 'd_st_cur', 'fdg_observ', 'spl_grp_id', 'comment', 'elev_min', 'gso_sep', 'srv_code', 'f_no_intfr', 'plan_categ', 'pfd_pk_7g', 'ra_stn_type', 'eirp_nom', 'sensitivity', 'f_1143a', 'd_first_ntf', 'f_no_comment', 'f_nfd_lnk', 'prv_pub_grp_id', 'f_sa_change', 'f_fdg_reqd', 'cmp_grp_id', 'f_cmp_str', 'f_cmp_rec', 'f_cmp_freq', 'f_cmp_emi', 'f_cmp_eas', 'f_cmp_prov', 'f_cmp_sas', 'f_cmp_gpub', 'f_cmp_fdg']},
            // {name: 'grp_aff_rec', header: ['grp_id', 'aff_rec_id']},
            // {name: 'grp_lnk', header: ['grp_id', 'lnk_grp_id', 'ntc_id', 'lnk_ntc_id', 'ntf_rsn', 'lnk_ntf_rsn']},
            // {name: 'history', header: ['ntc_id', 'seq_no', 'oper_id', 'd_hist', 'st_cur', 'hist_text']},
            // {name: 'hor_elev', header: ['ntc_id', 'azm', 'elev_ang', 'hor_dist', 'f_cmp_rec']},
            // {name: 'link_epm', header: ['grp_id', 'seq_e_as', 'seq_assgn', 'seq_emiss', 'epm']},
            // {name: 'mask_info', header: ['ntc_id', 'mask_id', 'freq_min', 'freq_max', 'f_mas', 'f_mask_type']},
            // {name: 'mask_lnk1', header: ['grp_id', 'seq_no', 'ntc_id', 'orb_id', 'sat_orb_id', 'mask_id']},
            // {name: 'mask_lnk2', header: ['grp_id', 'seq_no', 'seq_e_as', 'ntc_id', 'orb_id', 'sat_orb_id', 'mask_id']},
            // {name: 'mod_char', header: ['grp_id', 'seq_emiss', 'i_mod_typ', 'freq_low', 'freq_hi', 'freq_dev', 'freq_dev_tv', 'i_pre_emph', 'i_mplx_typ', 'bit_rate', 'nbr_phase', 'attch_sig', 'ampl_mod', 'freq_dev_fm', 'freq_swp', 'i_nrgy_dsp', 'i_nrgy_dsp_typ', 'attch_mod', 'i_tv_sys', 'i_sound_bc', 'i_baseband', 'range_agc']},
            // {name: 'ngma', header: ['ntc_id', 'ngma_id', 'act_code', 'strp_id_fr', 'strp_id_to', 'noise_t_lo', 'gain_as_lo', 'noise_t_hr', 'gain_as_hr', 'stn_name', 'f_cmp_rec']},
            // {name: 'non_geo', header: ['ntc_id', 'sat_name', 'ref_body', 'nbr_sat_nh', 'nbr_sat_sh', 'nbr_plane', 'nbr_sat_td', 'density', 'avg_dist', 'f_x_zone', 'x_zone', 'f_epfd', 'f_active', 'attch_x_zone', 'f_pfd_lim', 'attch_simult_ops', 'f_sdm', 'f_constell', 'multi_config_type', 'nbr_config', 'attch_multi_config', 'examset_type', 'attch_qv']},
            // {name: 'notice', header: ['ntc_id', 'prov', 'plan_id', 'adm', 'ntwk_org', 'ntf_occurs', 'ntf_rsn', 'st_cur', 'f_aa_type', 'act_code', 'd_rcv', 'wic_no', 'wic_part', 'd_wic', 'f_adm_proxi', 'ntc_type', 'adm_ref_id', 'd_adm', 'tgt_ntc_id', 'f_int_ext', 'd_st_cur', 'st_prv', 'd_upd', 'f_basic', 'f_spl', 'spl_ntc_id', 'ntwk_pack', 'f_mod_type', 'f_val_cat', 'cmp_ntc_id', 'f_cmp_str', 'f_cmp_rec', 'f_cmp_orb', 'f_cmp_strp', 'f_cmp_ngma', 'f_cmp_hori', 'f_cmp_elev', 'f_cmp_pfd', 'f_cmp_oper', 'f_cfex', 'f_val', 'f_mod', 'f_aes_char', 'prov_desc', 'f_partial_sup']},
            // {name: 'ntc_commit', header: ['ntc_id', 'commit_type']},
            // {name: 'ntc_lnk', header: ['ntc_id', 'lnk_ntc_id', 'ntf_rsn', 'lnkntf_rsn']},
            // {name: 'ntc_lnk_ref', header: ['plan_id', 'ntc_id', 'pbeam_name', 'adm', 'long_nom']},
            // {name: 'ntc_memo', header: ['ntc_id', 'adm_remark', 'br_comment']},
            // {name: 'orbit', header: ['ntc_id', 'orb_id', 'nbr_sat_pl', 'right_asc', 'inclin_ang', 'prd_ddd', 'prd_hh', 'prd_mm', 'apog', 'apog_exp', 'perig', 'perig_exp', 'perig_arg', 'op_ht', 'op_ht_exp', 'f_stn_keep', 'rpt_prd_dd', 'rpt_prd_hh', 'rpt_prd_mm', 'rpt_prd_ss', 'f_precess', 'precession', 'long_asc', 'keep_rnge', 'f_sunsynch', 'lt_type', 'lt_ref', 'f_cmp_rec', 'f_cmp_pha']},
            // {name: 'orbit_lnk', header: ['ntc_id', 'emi_rcp', 'beam_name', 'orb_id', 'f_all_sat']},
            // {name: 'ovrl_epm', header: ['grp_id_up', 'grp_id', 'seq_e_as_dn', 'seq_asn_up', 'seq_asn_dn', 'seq_emi_up', 'seq_emi_dn', 'oepm']},
            // {name: 'phase', header: ['ntc_id', 'orb_id', 'orb_sat_id', 'phase_ang', 'd_ref', 't_ref', 'f_cmp_rec']},
            // {name: 'pl_strap', header: ['ntc_id', 'freq_dn', 'freq_up', 'grp_id_dn', 'grp_id_up', 'pbeam_name', 'multiBeam_set', 'exop_set', 'f_victim_op', 'agg_tolerance']},
            // {name: 'plan_pub', header: ['ntc_id', 'wic_no', 'plan_pub_type']},
            // {name: 'provn', header: ['grp_id', 'coord_prov', 'agree_st', 'seq_no', 'coord_st', 'adm', 'ntwk_org', 'ctry']},
            // {name: 'pub_ssn', header: ['ntc_id', 'seq_no', 'ssn_ref', 'ssn_no', 'ssn_rev', 'ssn_rev_no']},
            // {name: 'pwr_ctrl', header: ['grp_id', 'seq_assgn', 'seq_emiss', 'pwr_ctrl']},
            // {name: 'res49_sel', header: ['grp_id', 'sat_name', 'long_nom', 'ntf_rsn', 'adm', 'ntwk_org', 'd_inuse', 'ntc_id', 'st_cur', 'd_prot_eff', 'freq_min', 'freq_max', 'wic_no', 'd_wic', 'act_code', 'emi_rcp', 'beam_name', 'ntc_type', 'd_reg_g']},
            // {name: 's_as_stn', header: ['grp_id', 'sat_name', 'beam_name', 'act_code', 'beam_old', 'sat_old', 'stn_type', 'long_nom', 'f_cmp_rec']},
            // {name: 's_beam', header: ['ntc_id', 'emi_rcp', 'beam_name', 'beam_old', 'f_steer', 'gain', 'gain_x', 'beamlet', 'bore_long', 'bore_lat', 'maj_axis', 'min_axis', 'orient', 'pnt_acc', 'rot_acc', 'pattern_id', 'pattern_id_x', 'freq_min', 'freq_max', 'sr_type', 'act_code', 'ang_alpha', 'ang_beta', 'attch_alpha_beta', 'attch_e', 'attch_e_x', 'attch_elev', 'attch_gain', 'attch_loss', 'pwr_max_4k', 'pwr_avg_4k', 'pwr_max_1m', 'pwr_avg_1m', 'prot_ratio', 'f_fdg_reqd', 'f_tx_vis', 'tx_ang_min', 'attch_pfd_steer', 'f_pfd_steer_default', 'f_all_orbit', 'f_co_change', 'f_aggso_change', 'cmp_ntc_id', 'cmp_beam', 'f_cmp_str', 'f_cmp_rec', '']},
            // {name: 'sat_lnk', header: ['ntc_id', 'emi_rcp', 'beam_name', 'orb_id', 'orb_sat_id']},
            // {name: 'sat_oper', header: ['ntc_id', 'lat_fr', 'lat_to', 'nbr_op_sat']},
            // {name: 'sat_sys_provn', header: ['plan_id', 'ntwk_pack', 'coord_prov', 'agree_st', 'ific_no', 'adm', 'ntwk_org']},
            // {name: 'scraft_cmr_freq', header: ['itu_scraft_id', 'seq_no', 'freq_min', 'freq_max', 'freq_sym']},
            // {name: 'scraft_cmr_syst', header: ['itu_scraft_id', 'ntwk_name', 'lsp_name', 'vehicle', 'd_exe', 'facility', 'nfct_name', 'nbr_sat', 'd_exe_m', 'd_deliv', 'd_launch']},
            // {name: 'sps_results', header: ['ntc_id', 'ntwk_pack', 'ntc_id_aff', 'pbeam_name', 'aff_ch_pfd', 'pfd_exc_max', 'aff_ch_epm', 'epm_c2i_dgr_max', 'aff_chs', 'pfd_exc', 'epm_dgr', 'freq_band']},
            // {name: 'srs_ooak', header: ['version_no', 'version_no_sub', 'd_create', 'd_last_export', 'comment']},
            // {name: 'srv_area', header: ['grp_id', 'ctry']},
            // {name: 'srv_cls', header: ['grp_id', 'seq_no', 'stn_cls', 'nat_srv']},
            // {name: 'strap', header: ['ntc_id', 'strp_id', 'act_code', 'beam_up', 'beam_dn', 'freq_symup', 'freq_up', 'freq_symdn', 'freq_dn', 'f_cmp_rec']},
            // {name: 'tr_aff_ntw', header: ['ntc_id', 'coord_prov', 'agree_st', 'aff_ntc_id', 'adm', 'ntwk_org', 'sat_name', 'long_nom', 'ntf_rsn', 'coord_st', 'st_aff', 'f_cause', 'f_rec', 'd_prot_inc', 'wic_no']},
            // {name: 'tr_provn', header: ['ntc_id', 'coord_prov', 'agree_st', 'wic_no', 'seq_no', 'coord_st', 'adm', 'ntwk_org', 'ctry']},
        ],
    },
    {
        menu: "kominfo_pnbp",
        submenu: [
            { name: 'pnbp_1', header: ['jenis_pnbp', 'blu_or_not', 'tahun', 'total', 'created_at', 'updated_at'] },
            { name: 'pnbp_2', header: ['jenis_pnbp', 'target', 'realisasi', 'tahun', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_siap",
        submenu: [
            { name: 'ta_absen', header: ['nip', 'tgl_masuk', 'kode_absen', 'kode_range', 'jam_masuk', 'jam_keluar', 'isi_manual', 'waktu_simpan', 'created_at', 'updated_at'] }, //done
            { name: 'ta_angka_kredit', header: ['nip', 'tgl_penetapan', 'pak_1a1', 'pak_1a2', 'pak_1b', 'pak_1c', 'pak_1d', 'pak_1e', 'pak_2', 'catatan', 'kode_pejabat', 'path_filee', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_bagian', header: ['kode_bagian', 'nama_bagian', 'kode_unit', 'aktif', 'mulai', 'sampai', 'waktu_simpan', 'created_at', 'updated_at'] },
            { name: 'ta_cabang', header: ['kode_cabang', 'nama_cabang', 'jadwal_apel', 'entry_pin', 'id_kab_kota', 'created_at', 'updated_at'] },
            { name: 'ta_eselon', header: ['kode_eselon', 'nama_eselon', 'nilai_bopt', 'created_at', 'updated_at'] },
            { name: 'ta_grade', header: ['kode_grade', 'nilai_grade', 'created_at', 'updated_at'] },
            { name: 'ta_hukuman_disiplin', header: ['nip', 'tgl_awal', 'tgl_akhir', 'kode_potongan', 'tgl_penetapan', 'dokumen_disiplin', 'created_at', 'updated_at'] }, //done
            { name: 'ta_ijin', header: ['kode_ijin', 'ket_ijin', 'perhitungan', 'ketentuan', 'jatah_ijin', 'kredit', 'berlaku', 'dasar', 'proses_diawal', 'kode_tidak_hadir', 'hitung_hari_kerja', 'hitung_jam_kerja', 'potongan', 'kolektif', 'created_at', 'updated_at'] },
            { name: 'ta_ijin_berlapis', header: ['kode_ijin', 'kode_ijin_lapisan', 'hari_ke', 'sd_hari_ke', 'potongan_tkk', 'created_at', 'updated_at'] },
            { name: 'ta_ijin_hari', header: ['nip', 'tgl_awal', 'tgl_akhir', 'kode_ijin', 'jml_hari', 'tgl_jatah', 'bukti_ijin', 'status', 'waktu_simpan', 'flag_dokumen', 'username', 'catatan_ijin', 'no_surat', 'kota_asal', 'kota_tujuan', 'jenis_transportasi', 'no_surat_dokter', 'negara_asal', 'negara_tujuan', 'id_ijin', 'nip_verifikator', 'created_at', 'updated_at'] }, //done
            { name: 'ta_ijin_jam', header: ['nip', 'tgl_ijin', 'jam_awal', 'jam_akhir', 'kode_ijin', 'alasan_ijin', 'ijin_dinas', 'status_ijin', 'waktu_simpan', 'ijin_ke', 'flag_dokuen', 'nip_verifikasi', 'tgl_disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_jabatan', header: ['kode_jabatan', 'nama_jabatan', 'created_at', 'updated_at'] },
            { name: 'ta_karyawan', header: ['nip', 'pin', 'nama', 'non_aktif', 'template', 'tgl_lahir', 'no_mesin', 'kode_cabang', 'kode_departemen', 'kode_jabatan', 'kode_golongan', 'kode_shift', 'tmt', 'aktif', 'kode_unit', 'kode_bagian', 'kode_subbagian', 'kode_eselon', 'kode_status_pegawai', 'waktu_simmpan', 'jabatan_struktural', 'kode_grade', 'npwp', 'nama_bank', 'norek_bank', 'kode_nomenklatur', 'kode_jabatan_plt', 'kode_tugas', 'created_at', 'updated_at'] }, //done
            { name: 'ta_lembur', header: ['nip', 'tgl_spl', 'jam_awal_spl', 'jam_akhir_spl', 'jenis_kerja_spl', 'status_lembur', 'waktu_simpan', 'created_at', 'updated_at'] }, //done
            { name: 'ta_libur', header: ['tgl_libur', 'ket_libur', 'created_at', 'updated_at'] }, //done
            { name: 'ta_libur_regional', header: ['tgl_libur', 'kode_cabang', 'ket_libur', 'id_libur', 'created_at', 'updated_at'] }, //done
            { name: 'ta_nomenklatur', header: ['kode_nomenklatur', 'nama_nomenklatur', 'honorarium_nomenklatur', 'created_at', 'updated_at'] },
            { name: 'ta_otoritas_departemen', header: ['username', 'kode_departemen', 'created_at', 'updated_at'] },
            { name: 'ta_pnbp', header: ['nip', 'tahun', 'kode_golongan', 'kode_nomeklatur', 'honorarium', 'pot_a', 'pot_b', 'pot_c', 'pot_d', 'pot_e', 'total_pot', 'honorarium_setelah_pot', 'pajak_gol', 'potongan_pajak', 'honorarium_diterima', 'created_at', 'updated_at'] },
            { name: 'ta_pola', header: ['kode_shift', 'tgl_mulai', 'nama_group', 'format', 'libur_nasional', 'kode_departemen', 'created_at', 'updated_at'] }, //done
            { name: 'ta_pph', header: ['nip', 'tahun', 'bulan', 'gjpokok', 'tjistri', 'tjanak', 'jmlgjtj', 'tjberas', 'tjstruk', 'tjfungsi', 'tjupns', 'nilai_tk', 'pembul', 'batas_ptkp', 'jml_penghasila_a', 'biaya_jabatan_a', 'iuran_pensiuan_a', 'pengurangan_a', 'neto_a', 'neto_setahun_a', 'ptkp_a', 'pkp_a', 'pkp_bulan', 'pph', 'pph_lapisan1_a', 'pph_lapisan2_a', 'pph_lapisan3_a', 'pph_lapisan4_a', 'pph_a', 'pph_sebelum', 'jumlah_penghasial_b', 'biaya_jabatan_b', 'iuran_pensiun_b', 'pengurangan_b', 'neto_b', 'neto_setahun_b', 'ptkp_b', 'pkp_b', 'pkp_bulan_b', 'pkp_bulat_b', 'pph_lapisan1_b', 'pph_lapisan2_b', 'pph_lapisan3_b', 'pph_lapisan4_b', 'pph_b', 'pph_sebulan_b', 'pajak_tk', 'tjdaerah', 'tjpercil', 'tjlain', 'tjkompen', 'tjpph', 'potpph', 'bersih', 'potpfk10', 'potpfkbul', 'potswrum', 'potkelbtj', 'pottabr', 'potlain', 'kdkawin', 'created_at', 'updated_at'] },
            { name: 'ta_ptkp', header: ['kode_kawin', 'batas_ptpkp', 'ket_kawin', 'ptpkp', 'created_at', 'updated_at'] },
            { name: 'ta_range', header: ['kode_range', 'kode_absen', 'jam_awal', 'jam_akhir', 'tol_masuk', 'tol_pulang', 'limit_awal', 'limit_akhir', 'jam_kerja', 'jam_istirahat', 'waktu_flexibel', 'created_at', 'updated_at'] },
            { name: 'ta_range_detail', header: ['kode_range', 'kode_departemen', 'ket_range', 'created_at', 'updated_at'] },
            { name: 'ta_riwayat_karyawan', header: ['nip', 'bulan', 'tahun', 'kode_cabang', 'kode_departemen', 'kode_jabatan', 'kode_golongan', 'kode_unit', 'kode_bagian', 'kode_subbagian', 'kode_eselon', 'kode_status_pegawai', 'jabatan_struktural', 'kode_grade', 'lap_keuangan', 'nama_bank', 'norek_bank', 'kode_nomenklatur', 'created_at', 'updated_at'] },
            { name: 'ta_status_pegawai', header: ['kode_status', 'nama_status', 'prosen_grade', 'created_at', 'updated_at'] },
            { name: 'ta_subbagian', header: ['kode_subbagian', 'nama_subbagian', 'kode_bagian', 'aktif', 'mulai', 'sampai', 'waktu_simpan', 'created_at', 'updated_at'] },
            { name: 'ta_unit', header: ['kode_unit', 'nama_unit', 'kode_cabang', 'aktif', 'mulai', 'sampai', 'waktu_simpan', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_siemon",
        submenu: [
            { name: 'lap_monev_perangkat_online', header: ['jenis', 'sertifikat', 'non_sertifikat', 'bulan', 'tahun', 'created_at', 'updated_at'] },
            { name: 'rekap_lap_mon_perangkat_upt', header: ['upt', 'pelaksanaan', 'terindentifikasi', 'legal', 'legal_tidak_berlabel', 'bulan', 'tahun', 'created_at', 'updated_at'] },
            { name: 'rekap_lap_montib_perangkat_upt', header: ['upt', 'jumlah_pelaksanaan_monitoring', 'jumlah_perangkat_terindentifikasi', 'bersertifikat_berlabel', 'bersertifikat_tidak_sesuai_spesifikasi_teknis', 'tidak_bersertifikat', 'jumlah_penertiban', 'surat_teguran_penanganan', 'penyegelan_perangkat', 'pengamanan_atau_penyitaan_perangkat', 'persentase_penyelesaian_penanganan_pelanggaran_perangkat', 'bulan', 'tahun', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_simpeg",
        submenu: [
            { name: 'data_ppns_2023', header: ['nama', 'satker', 'gol', 'nip', 'ppns_bidang', 'created_at', 'updated_at'] },
            { name: 'data_jabatan_fungsional_pfr', header: ['nama', 'nip', 'tempat_tanggal_lahir', 'jenis', 'pangkat_golongan_ruang', 'tmt_pangkat', 'jenjang_jabatan', 'tmt_jabatan', 'satuan_kerja', 'created_at', 'updated_at'] },
            { name: 'riwayat_pendidikan', header: ['nip', 'tahun_lulus', 'kode_tingkat', 'kode_jurusan', 'jurusan', 'nama_Sekolah', 'pimpinan', 'kota_sekolah', 'nomor_ijazah', 'tgl_ijazah', 'ipk_nem', 'disetujui', 'waktu_simpan', 'id_pendidikan', 'created_at', 'updated_at'] },
            { name: 't_pns', header: ['nip', 'nomor_sk_pns', 'tgl_sk_pns', 'kode_golongan', 'kode_pejabat', 'kode_sumpah', 'created_at', 'updated_at'] },
            { name: 'ta_angka_kredit', header: ['nip', 'tgl_penetapan', 'pak_1a1', 'pak_1a2', 'pak_1b', 'pak_1c', 'pak_1d', 'pak_1e', 'pak_2', 'catatan', 'kode_pejabat', 'path_filee', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_bagian', header: ['kode_bagian', 'nama_bagian', 'kode_unit', 'aktif', 'created_at', 'updated_at'] },
            { name: 'ta_bahasa', header: ['nip', 'nama_bahasa', 'jenis_bahasa', 'kemampuan_bahasa', 'id_bahasa', 'waktu_simpan', 'created_at', 'updated_at'] },
            { name: 'ta_cabang', header: ['kode_cabang', 'nama_cabang', 'alamat_cabang', 'tlp', 'nama_penuh', 'kecamatan', 'kabupaten', 'provinsi', 'id_kab_kota', 'created_at', 'updated_at'] },
            { name: 'ta_cpns', header: ['nip', 'nomor_nota_pers_bkn', 'tgl_nota', 'nomor_sk_cpns', 'tgl_sk_cpns', 'kode_golongan', 'tmt_cpns', 'kode_pejabat', 'path_file', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_departemen', header: ['kode_departemen', 'nama_departemen', 'kode_tree', 'created_at', 'updated_at'] },
            { name: 'ta_diklat_eselon', header: ['kode_eselon', 'tunjangan_eselon', 'nilai_bopt'] },
            { name: 'ta_diklat_fungsional', header: ['kode_diklat_fungsional', 'nama_diklat_fungsional', 'created_at', 'updated_at'] },
            { name: 'ta_diklat_struktural', header: ['kode_diklat_struktural', 'nama_diklat_struktural', 'created_at', 'updated_at'] },
            { name: 'ta_diklat_teknis', header: ['kode_diklat_teknis', 'nama_diklat_teknis', 'created_at', 'updated_at'] },
            { name: 'ta_golongan', header: ['kode_golongan', 'nama_golongan', 'nilai_bopt', 'pajak_golongan', 'tarif_makan', 'created_at', 'updated_at'] },
            { name: 'ta_jabatan', header: ['kode_jabatan', 'nama_jabatan', 'tarif_makan', 'created_at', 'updated_at'] },
            { name: 'ta_jasa', header: ['id_jasa', 'nip', 'tgl_sk', 'nomor_sk', 'asal_jasa', 'nama_jasa', 'kode_pejabat', 'path_file', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_jenis_jabatan', header: ['kode_jenis_jabatan', 'nama_jenis_jabatan'] },
            { name: 'ta_jenis_jasa', header: ['kode_jenis_jasa', 'nama_tanda_jasa'] },
            { name: 'ta_jenis_kenaikan_pangkat', header: ['kode_jenis_kenaikan_pangkat', 'nama_jenis_kenaikan'] },
            { name: 'ta_jurusan', header: ['kode_jurusan', 'nama'] },
            { name: 'ta_karyawan', header: ['nip', 'kode_departemen', 'kode_jabatan', 'kode_golongan', 'kode_cabang', 'nama', 'alamat1', 'rtw1', 'kel', 'kec', 'kota1', 'kdpos', 'propinsi', 'negara1', 'tlp1', 'alamat2', 'rtw2', 'kel2', 'kec2', 'kota2', 'kdpos2', 'pvs2', 'negara2', 'tlp2', 'tmpt_lahir', 'tgl_lahir', 'npwp', 'jk', 'agama', 'no_hp', 'email1', 'email2', 'no_ktp', 'kebangsaan', 'pendidikan', 'gol', 'tgl_msk', 'status_masuk', 'status_gaji', 'status_kerja', 'komponen', 'metode', 'penghasilan', 'pph_sblmnya', 'tgl_berhenti', 'sts_brhnti', 'wkt_pecbaan', 'spgr', 'status_nikah', 'jumlah_anak', 'tanggungan', 'kode_byr', 'kode_bank', 'no_rek', 'keluar', 'gelar_dpn', 'gelar_blkg', 'kd_sts_pgw', 'kd_kdk_pgw', 'no_karpeg', 'no_akses', 'no_taspen', 'no_karis_karsu', 'disetujui', 'waktu_simpan', 'kode_unit', 'kode_bagian', 'kode_eselon', 'kode_subbagian', 'jabatan', 'jbt_struktural', 'tinggi', 'bb', 'hobby', 'pin', 'non_aktif', 'kode_jabatan_plt', 'kode_tgs', 'penyetaraan', 'kode_tim_kerja'] }, //done
            { name: 'ta_kedudukan_pegawai', header: ['kode_kedudukan_pegawai', 'nama', 'created_at', 'updated_at'] },
            { name: 'ta_pendidikan', header: ['kode_pendidikan', 'nama'] },
            { name: 'ta_penilaian', header: ['nip', 'tgl_nilai', 'kesetiaan', 'prestasi', 'ketaatan', 'tgg_jwb', 'kejujuran', 'krj_sama', 'prakarsa', 'kepimpinan', 'catatan', 'kode_penilaian', 'path_file', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_riwayat_diklat_fungsional', header: ['nip', 'tgl_mulai', 'tgl_selesai', 'kode_diklat', 'tempat', 'penyelengara', 'angkatan', 'jml_jam', 'satuan', 'nomor_sttp', 'tgl_sttp', 'disetujui', 'id_riwayat', 'path_file', 'created_at', 'updated_at'] }, //done
            { name: 'ta_riwayat_diklat_struktural', header: ['nip', 'tgl_mulai', 'tgl_selesai', 'kode_diklat', 'tempat', 'penyelengara', 'angkatan', 'jml_jam', 'satuan', 'nomor_sttp', 'tgl_sttp', 'disetujui', 'id_riwayat', 'path_file', 'created_at', 'updated_at'] }, //done
            { name: 'ta_riwayat_diklat_teknis', header: ['nip', 'tgl_mulai', 'tgl_selesai', 'kode_diklat', 'tempat', 'penyelengara', 'angkatan', 'jml_jam', 'satuan', 'nomor_sttp', 'tgl_sttp', 'disetujui', 'id_riwayat', 'path_file', 'created_at', 'updated_at'] }, //done
            { name: 'ta_riwayat_seminar', header: ['nip', 'tgl_mulai', 'tgl_selesai', 'kode_diklat', 'tempat', 'penyelengara', 'angkatan', 'jml_jam', 'satuan', 'nomor_sttp', 'tgl_sttp', 'disetujui', 'id_riwayat', 'path_file', 'created_at', 'updated_at'] }, //done
            { name: 'ta_sklain', header: ['id_sklain', 'nip', 'nomor_sklain', 'tgl_sklain', 'jenis_sklain', 'tahun', 'path_file', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_skp', header: ['nip', 'tahun', 'nilai_capaian', 'orientasi', 'integritas', 'kotmitmen', 'disiplin', 'kerjasama', 'kepimimpinan', 'nilai_perilaku_kerja', 'nilai_prestasi_kerja', 'id_skp', 'path_file', 'disetujui', 'created_at', 'updated_at'] },
            { name: 'ta_status_pegawai', header: ['kode_status', 'nama_status', 'prosen_grade', 'created_at', 'updated_at'] },
            { name: 'ta_stlud', header: ['kode_status', 'keterangan', 'created_at', 'updated_at'] },
            { name: 'ta_subbagian', header: ['kode_status', 'nama_subbagian', 'kode_bagian', 'aktif', 'created_at', 'updated_at'] },
            { name: 'ta_tgs_luar_negri', header: ['id_tugas', 'nip', 'tgl_mulai', 'tgl_selesai', 'negara', 'jenis_kunjungan', 'kode_pejabat', 'nomor_sk', 'tgl_sk', 'jml_lama', 'satuan', 'sumber', 'tahun', 'path_file', 'disetujui', 'created_at', 'updated_at'] }, //done
            { name: 'ta_tim_kerja', header: ['kode', 'nama', 'created_at', 'updated_at'] },
            { name: 'ta_unit', header: ['kode', 'nama', 'kode_cabang', 'aktif', 'created_at', 'updated_at'] },
            { name: 'ta_unit_2', header: ['aktif', 'nama_unit', 'ta_unit', 'provinsi_fix', 'unit_provinsi', 'province', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_staging",
        submenu: [
            { name: 'data_mon_hf', header: ['stasiun_monitoring', 'tahun', 'jumlah', 'created_at', 'updated_at'] },
            { name: 'data_sertifikasi', header: ['id', 'plg_id', 'nib', 'nomor_sertifikat', 'tgl_sertifikat', 'resertifikasi', 'nama_perangkat', 'merek', 'model', 'nama_dagang', 'jenis_permohonan', 'kelompok_perangkat', 'peruntukan', 'hs_code', 'nama_perusahaan', 'nama_pabrikan', 'negara_pabrikan', 'balai_uji_lhu', 'negara_balai_uji_lhu', 'balai_uji_lhu_dua', 'negara_balai_uji_lhu_dua', 'balai_uji_emc', 'balai_uji_safety', 'negara_balai_uji_safety', 'frekuensi', 'created_at', 'updated_at'] }, //done
            // {name: 'event_penting', header: ['id', 'pita_frekuensi', 'frekuensi_mhz', 'level_dbm', 'penggunaan', 'indentifikasi', 'status', 'kondisi', 'jenis_indentifikasi', 'jenis_event', 'tahun', 'created_at', 'updated_at']},
            { name: 'frekuensi_terbanyak', header: ['dinas', 'total', 'tahun', 'created_at', 'updated_at'] },
            { name: 'hasil_monitor', header: ['dinas', 'sub_service', 'termonitor', 'terindentifikasi', 'legal', 'illegal', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'hs_telekomunikasi', header: ['tahun', 'kode_hs', 'jenis', 'total', 'created_at', 'updated_at']},
            // {name: 'koperasi_jenis', header: ['id', 'koperasi', 'yk', 'volume_usaha', 'triwulan', 'slm', 'tahun', 'sisa_hasil_usaha', 'rat', 'pasif', 'modal_sendiri', 'modal_luar', 'manajer_wanita', 'manajer_pria', 'kp', 'aktif', 'karyawan_wanita', 'karyawan_pria', 'jumlah_anggota', 'jumlah_karyawan', 'jumlah_manajer', 'jml_ap', 'gk', 'diy', 'btl', 'asset', 'anggota_wanita', 'anggota_pria', 'created_at', 'updated_at']},
            // {name: 'koperasi_kelompok', header: ['id', 'koperasi', 'yk', 'volume_usaha', 'triwulan', 'slm', 'tahun', 'sisa_hasil_usaha', 'rat', 'pasif', 'modal_sendiri', 'modal_luar', 'manajer_wanita', 'manajer_pria', 'kp', 'aktif', 'karyawan_wanita', 'karyawan_pria', 'jumlah_anggota', 'jumlah_karyawan', 'jumlah_manajer', 'jml_ap', 'gk', 'diy', 'btl', 'asset', 'anggota_wanita', 'anggota_pria', 'created_at', 'updated_at']},
            // {name: 'koperasi_publik', header: ['id', 'koperasi', 'yk', 'volume_usaha', 'triwulan', 'slm', 'tahun', 'sisa_hasil_usaha', 'rat', 'pasif', 'modal_sendiri', 'modal_luar', 'manajer_wanita', 'manajer_pria', 'kp', 'aktif', 'karyawan_wanita', 'karyawan_pria', 'jumlah_anggota', 'jumlah_karyawan', 'jumlah_manajer', 'jml_ap', 'gk', 'diy', 'btl', 'asset', 'anggota_wanita', 'anggota_pria', 'created_at', 'updated_at']},
            { name: 'mon_hf', header: ['administrasi', 'kode', 'tahun', 'jumlah', 'created_at', 'updated_at'] },
            // {name: 'pdb', header: ['tahun', 'pdb_lapangan', 'triwulan1_harga_konstan', 'triwulan2_harga_konstan', 'triwulan3_harga_konstan', 'triwulan4_harga_konstan', 'tahunan_harga_konstan', 'triwulan1_harga_harga_berlaku', 'triwulan2_harga_harga_berlaku', 'triwulan3_harga_harga_berlaku', 'triwulan4_harga_harga_berlaku', 'tahunan_harga_harga_berlaku', 'created_at', 'updated_at']},
            // {name: 'penggunaan_frekuensi', header: ['id', 'client', 'pita_frekuensi', 'frek_tx', 'frek_rx', 'perangkat', 'model', 'brands', 'desc', 'jumlah', 'stasiun', 'jenis_penggunaan', 'tahun', 'created_at', 'updated_at']},
            { name: 'penggunaan_frekuensi_2', header: ['id', 'penggunaan', 'tipe_perangkat', 'merk_perangkat', 'rentang_mhz', 'frekuensi', 'desc', 'client', 'equipment', 'penyelenggara', 'tx', 'rx', 'location', 'service', 'jenis_penggunaan', 'created_at', 'updated_at'] },
            { name: 'peraturan_sdppi', header: ['nama_peraturan', 'Peraturan', 'tgl_penetapan', 'tgl_pengundangan', 'keterangan'] }, //done
            { name: 'periode_tahunan_bwa', header: ['pita', 'operator', 'total', 'tahun', 'created_at', 'updated_at'] },
            { name: 'perjanjian_sdppi', header: ['nama_perjanjian', 'perjanjian', 'tgl_penetapan', 'tgl_pengundangan', 'keterangan'] }, //done
            // {name: 'pertumbuhan_pdb', header: ['tahun', 'pdb_lapangan', 'triwulan1_kumulatif', 'triwulan2_kumulatif', 'triwulan3_kumulatif', 'triwulan4_kumulatif', 'tahunan_kumulatif', 'triwulan1_berantai', 'triwulan2_berantai', 'triwulan3_berantai', 'triwulan4_berantai', 'tahunan_berantai', 'triwulan1_dengan_triwulan', 'triwulan2_dengan_triwulan', 'triwulan3_dengan_triwulan', 'triwulan4_dengan_triwulan', 'tahunan_dengan_triwulan', 'created_at', 'updated_at']},
            { name: 'pita_frekuensi_by_tahun', header: ['pita', 'termonitor', 'terindentifikasi', 'legal', 'illegal', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'refarming', header: ['judul', 'rentang_pita_sebelum', 'wilayah_layanan_sebelum', 'rentang_pita_setelah', 'wilayah_layanan_setelah', 'created_at', 'updated_at']},
            { name: 'rekap_frekuensi_by_tahun', header: ['upt', 'termonitor', 'terindentifikasi', 'legal', 'illegal', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'ringkasan_apbn', header: ['judul', 'apbn_2020', 'perpes_54', 'perpes_72', 'lkpp', 'apbn_2021', 'created_at', 'updated_at']},
            // {name: 'sianti', header: ['nama', 'total', 'bulan', 'tahun', 'created_at', 'updated_at']},
            { name: 'smfr', header: ['index', 'unit_id', 'provinsi_id', 'kota_id', 'kabupaten', 'kecamatan', 'kantor_pos', 'site_address', 'latitude', 'longitude', 'vendor', 'tahun', 'last_update', 'site_id', 'site_name', 'site_type', 'created_at', 'updated_at'] },
            { name: 'stasiun_hf', header: ['site_name', 'city', 'stasiun_name', 'coordinate', 'created_at', 'updated_at'] },
            // {name: 'tibnas_dan_rol', header: ['upt', 'provinsi', 'tgl_kegiatan', 'pengguna', 'frekuensi', 'dinas', 'jenis_pelanggaran', 'kab_kota', 'status', 'jenis_laporan', 'created_at', 'updated_at']},
        ],
    },
    {
        menu: "kominfo_staging2",
        submenu: [
            { name: 'alat_perangkat', header: ['perangkat', 'jumlah', 'tahun', 'jenis', 'created_at', 'updated_at'] },
            { name: 'balai_uji_tetap', header: ['nama', 'alamat', 'ruang_lingkup', 'kontak', 'created_at', 'updated_at'] },
            { name: 'cakupan_smfr', header: ['id', 'tahun', 'cakupan', 'created_at', 'updated_at'] },
            { name: 'capaian_spektrum', header: ['tahun', 'capaian', 'jumlah', 'created_at', 'updated_at'] },
            { name: 'data_imei', header: ['id', 'data_imei', 'jumlah', 'created_at', 'updated_at'] },
            { name: 'data_lokai_penempatan', header: ['cluster', 'jumlah_venue', 'jumlah_cabang', 'jumlah_pengawas', 'jumlah_perangkat', 'tahun', 'jenis', 'created_at', 'updated_at'] },
            // {name: 'data_lokasi_penempatan', header: ['cluster', 'jenis', 'jumlah_pengawas', 'jumlah_pengawas1', 'jumlah_pengawas2', 'jumlah_perangkat', 'jumlah_venue', 'tahun', 'jumlah_cabang', 'jumlah_pengawas2_split1', 'jumlah_pengawas_split1', 'jumlah_venue1', 'created_at', 'updated_at']},
            { name: 'dmp_sfr', header: ['equipment', 'penyelenggara', 'area', 'status', 'tahun', 'created_at', 'updated_at'] },
            { name: 'hasil_mon_sfr', header: ['jenis', 'sfr', 'jumlah', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'hs_telekomunikasi_new', header: ['id', 'hs_code', 'uraian', '2015_nilai', '2016_nilai', '2017_nilai', '2018_nilai', '2019_nilai', '2015_volume', '2016_volume', '2017_volume', '2018_volume', '2019_volume', 'created_at', 'updated_at']},
            { name: 'isr_smntara', header: ['id', 'equipment', 'bandwith', 'number_of_channels', 'created_at', 'updated_at'] },
            { name: 'jumlah_alat_ukur', header: ['perangkat', 'jumlah', 'tahun', 'status', 'created_at', 'updated_at'] },
            { name: 'jumlah_sdppi', header: ['unit', 'jumlah', 'tahun', 'status', 'created_at', 'updated_at'] },
            { name: 'lhu_tiap_balai', header: ['id', 'balai', 'jumlah_lhu', 'created_at', 'updated_at'] },
            { name: 'mon_sfr_perpanas', header: ['alat', 'sfr', 'jumlah', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'nilai_bhr', header: ['pita_frekuensi', 'total', 'tahun', 'created_at', 'updated_at']},
            // {name: 'opening_perangkat', header: ['no', 'perarngkat', 'jumlah', 'fungsi', 'created_at', 'updated_at']},
            { name: 'pengakuan_laboraturium', header: ['dasar_hukum', 'kelompok', 'jumlah_lab', 'masa_berlaku', 'created_at', 'updated_at'] },
            // {name: 'penyeselaian_pegujian', header: ['tahun', 'jumlah1', 'presentase1', 'jumlah2', 'presentasi2', 'created_at', 'updated_at']},
            { name: 'perangkat_digunakan', header: ['cluster', 'jumlah_ver', 'jumlah_per', 'fungsi', 'created_at', 'updated_at'] },
            { name: 'perangkat_pertandingan', header: ['cluster', 'jumlah_venue', 'Jumlah_perangkat', 'fungsi_perangkat', 'created_at', 'updated_at'] },
            { name: 'perangkat_wsbk_perpanas', header: ['id', 'perangkat', 'jumlah', 'keterangan', 'jenis', 'created_at', 'updated_at'] },
            // {name: 'perkembangan_pnbp', header: ['id', 'kementrian_kelembagaan', '2017', '2018', '2019', '2020', '2021', 'created_at', 'updated_at']},
            { name: 'personil_perangkat_open', header: ['id', 'lokasi', 'jumlah', 'jml2', 'created_at', 'updated_at'] },
            // {name: 'rekap_system_smfr', header: ['id', 'upt', 'jmlh_fix', 'jmlh_mobile', 'created_at', 'updated_at']},
            { name: 'sebaran_penggunaan', header: ['kabupaten', 'sfr', 'jumlah', 'tahun', 'created_at', 'updated_at'] },
            // {name: 'sebaran_penggunaan_smfr', header: ['COL 1', 'created_at', 'updated_at']},
            // {name: 'sfr_broadcast', header: ['lokasi', 'sfr', 'Jumlah', 'created_at', 'updated_at']},
            // {name: 'target_penambahan_spektrum', header: ['judul', '2020', '2021', '2022', '2023', '2024', 'created_at', 'updated_at']},
            { name: 'upt_personil_wsbk_perpanas', header: ['id', 'unit', 'jumlah', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "kominfo_staging3",
        submenu: [
            // {name: 'capaian_spektrum', header: ['calculation', 'capaian', 'tahun', 'jumlah', 'created_at', 'updated_at']},
            { name: 'dataset_jumlah_kab_termonitor', header: ['operator', 'pita', 'tahun', 'total', 'created_at', 'updated_at'] },
            { name: 'filing_new', header: ['filing_satelit', 'pengelola', 'slot_orbit', 'tgl', 'created_at', 'updated_at'] },
            //   {name: 'monitoring_hf_tetap', header: ['monitoring', '2017', '2018', '2019', '2020', '2021', 'created_at', 'updated_at']},
            { name: 'monitoring_hf_tetap_new', header: ['monitoring', 'tahun', 'value', 'created_at', 'updated_at'] },
            { name: 'satelit_filling', header: ['kegunaan', 'manufaktur', 'nama_satelit', 'slot_orbit', 'tgl_luncur', 'tgl_luncur_sp', 'tgl_luncur_sp2', 'tl_luncur_sp3', 'tempat_luncur', 'transponder', 'created_at', 'updated_at'] },
            { name: 'target_penambahan', header: ['jenis', 'tahun', 'jumlah', 'created_at', 'updated_at'] },
            { name: 'target_realisasi2', header: ['kode', 'jenis_pnbp', 'tahun', 'target', 'realisasi', 'created_at', 'updated_at'] },
        ],
    },
    {
        menu: "new_staging_req",
        submenu: [
            //   {name: 'maritim_penerbangan', header: ['f7', 'tahun', '2017', '2018', '2019', '2020', '2021']},
            { name: 'maritim_penerbangan_new', header: ['f7', 'tahun', 'value', 'created_at', 'updated_at'] },
            //   {name: 'stasiun_radio_pita_frekuensi', header: ['pita_frekuensi', '2017', '2018', '2019', '2020', '2021']},
            { name: 'stasiun_radio_pita_frekuensi_new', header: ['pita_frekuensi', 'tahun', 'value', 'created_at', 'updated_at'] },
            { name: 'balai_uji_lhu', header: ['acuan', 'alamat_pabrikan', 'alamat_perusahaan', 'balai_uji_emc', 'balai_uji_lhu_copy', 'balai_uji_lhu_dua', 'balai_uji_safety', 'frekuensi', 'hs_code', 'jenis_permohonan', 'kelompok_perangkat', 'merek', 'model', 'modul_perangkat', 'nama_balai_uji', 'nama_dagang', 'nama_pabrikan', 'nama_perangkat', 'nama_perangkat_copy', 'nama_perusahaan', 'negara_balai_uji_emc', 'negara_balai_uji_lhu', 'negara_balai_uji_lhu_copy', 'negara_balai_uji_lhu_dua', 'negara_balai_uji_safety', 'negara_pabrikan', 'nib', 'nomor_sertifikat', 'peruntukan', 'plg_id', 'resertifikasi', 'tanggal_sertifikat', 'tkdn', 'no'] },
            //   {name: 'laporan_lhu2', header: ['f8', 'tahun', '2017', '2018', '2019', '2020', '2021']},
            { name: 'laporan_lhu2_new', header: ['f8', 'tahun', 'value', 'created_at', 'updated_at'] },
            { name: 'sertifikat_asal_negara', header: ['negara', 'tanggal', 'no', 'jumlah'] },
            { name: 'jumlah_serser_data', header: ['bulan', 'tahun', 'jumlah_sertifikat'] },
            { name: 'jumlah_sp2', header: ['tahun', 'jumlah_sp2', 'no', 'zero'] },
        ],
    },
    {
        menu: "sdppi",
        submenu: [
            { name: 'aplikasinegara_pengujian', header: ['id', 'tahun', 'negara', 'jumlah_aplikasi', 'updated_at', 'created_at'] },
            { name: 'aplikasisubmit_kalibrasi', header: ['id', 'tahun', 'bulan', 'jumlah_submit', 'updated_at', 'created_at'] },
            { name: 'terbitlhu_pengujian', header: ['id', 'tahun', 'negara', 'jumlah_lhu', 'updated_at', 'created_at'] },
            { name: 'totalfitur_pengujian', header: ['id', 'tahun', 'fitur', 'jumlah_fitur', 'updated_at', 'created_at'] },
        ],
    },
    {
        menu: "staging_2023",
        submenu: [
            { name: 'data_filling_satelit_3', header: ['frekuensi', 'masa_operasional', 'nama_filling', 'slot_orbit', 'status', 'tgl_biu', 'f6', 'no'] },
            { name: 'ekspor_import', header: ['kolom', 'tahun', 'berat', 'nilai'] },
            { name: 'ekspor_sddpi', header: ['hs_code', 'nilai', 'tahun', 'uraian', 'no', 'volume'] },
            { name: 'import_sddpi', header: ['dlm_ribu', 'dlm_volume', 'hs_code', 'tahun', 'uraian', 'no'] },
            { name: 'kontribusi_lapangan_usaha', header: ['laju_tumbuh', 'lapangan_usaha', 'tahun', 'no'] },
            { name: 'lapangan_usaha2', header: ['lapangan_usaha', 'nilai', 'tahun', 'no'] },
            { name: 'new_refarming_sdppi', header: ['penyelengara', 'rentang_pita_sembelu', 'rentang_pita_setelah', 'wilayah_layanan1', 'wilayah_layanan2', 'zona', 'zona_1', 'no'] },
        ],
    },
];


// export const MENUS_DATALAKE_UPDATED = [
//   {
//     menu: "Dukungan Manajemen Direktorat Jenderal Sumber Daya dan Perangkat Pos dan Informatika",
//     submenu: [
//       {
//         childsubmenu: 'Hukum dan Kerjasama',
//         jenis: [
//           {
//             name: 'peraturan_sdppi',
//             url: 'kominfo_staging'
//           }
//         ],
//       },
//       {
//         childsubmenu: 'SDM',
//         jenis: [
//           {
//             name: 'perjanjian_sdppi',
//             url: 'kominfo_staging'
//           }
//         ],
//       },
//     ],
//   },
//   {
//     menu: "Bidang Penataan Sumber Daya",
//     submenu: [
//       {
//         childsubmenu: '',
//         jenis: [
//           {
//             name: 'capaian_spektrum',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'com_el',
//             url: 'kominfo_filing'
//           },
//           {
//             name: 'daftar_filling_baru',
//             url: ''
//           },
//           {
//             name: 'daftar_filling_satelit3',
//             url: ''
//           },
//           {
//             name: 'e_stn',
//             url: 'kominfo_filing'
//           },
//           {
//             name: 'refarming_new',
//             url: ''
//           },
//           {
//             name: 'satelit_menggunakan_filling_indonesia',
//             url: ''
//           },
//           {
//             name: 'target_penambahan_freq',
//             url: ''
//           },
//           {
//             name: 'target_penambahan_spektrum',
//             url: 'kominfo_staging2'
//           }
//         ],
//       },
//     ],
//   },
//   {
//     menu: "Bidang Pengendalian Sumber Daya dan Perangkat Pos dan Informatika",
//     submenu: [
//       {
//         childsubmenu: 'Monitor dan Penertiban Alat dan Perangkat Telekomunikasi',
//         jenis: [
//           {
//             name: 'lap_monev_perangkat_online',
//             url: 'kominfo_siemon'
//           },
//           {
//             name: 'rekap_lap_mon_perangkat_upt',
//             url: 'kominfo_siemon'
//           },
//           {
//             name: 'rekap_lap_montib_perangkat_upt',
//             url: 'kominfo_siemon'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'Monitor dan Penertiban Spektrum Frekuensi Radio',
//         jenis: [
//           {
//             name: 'data_mon_hf',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'frekuensi_terbanyak',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'mon_hf',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'periode_tahunan_bwa',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'pita_frekuensi_by_tahun',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'rekap_frekuensi_by_tahun',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'stasiun_hf',
//             url: 'kominfo_staging'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'SMFR',
//         jenis: [
//           {
//             name: 'cakupan_smfr',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'smfr',
//             url: 'kominfo_staging'
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menu: "Pelayanan Publik",
//     submenu: [
//       {
//         childsubmenu: 'Bidang Operasi Sumber Daya',
//         jenis: [
//           {
//             name: 'bts_ipfr',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'data_labuh',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'hasil_monitor',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'iar',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'ikrap',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'pengunjung_ppt',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'pita_frekuensi_by_tahun',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'radio_station_frekuensi',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'reor',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'respon_call',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'sertifikasi_kecakapan',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'sertifikat_reor',
//             url: 'kominfo_ditops'
//           },
//           {
//             name: 'stasiun_hf',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'statistik_tiket',
//             url: 'kominfo_ditops'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'Bidang Pengujian dan Kalibrasi Perangkat Telekomunikasi',
//         jenis: [
//           {
//             name: 'aplikasinegara_pengujian',
//             url: 'sddpi'
//           },
//           {
//             name: 'aplikasisubmit_kalibrasi',
//             url: 'sddpi'
//           },
//           {
//             name: 'jumlah_sertfikat_alat_ukur',
//             url: ''
//           },
//           {
//             name: 'jumlah_sp2',
//             url: ''
//           },
//           {
//             name: 'lhu_tiap_balai',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'stasiun_hf',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'terbitlhu_pengujian',
//             url: 'sddpi'
//           },
//           {
//             name: 'totalfitur_pengujian',
//             url: 'sddpi'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'Bidang Standardisasi Alat dan Perangkat Telekomunikasi',
//         jenis: [
//           {
//             name: 'balai_uji_tetap',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'data_imei',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'data_sertifikasi',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'pengakuan_laboraturium',
//             url: 'kominfo_staging2'
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menu: "Ekonomi Bidang Sumber Daya dan Perangkat Pos dan Informatika",
//     submenu: [
//       {
//         childsubmenu: '',
//         jenis: [
//           {
//             name: 'eskpor_sdppi',
//             url: ''
//           },
//           {
//             name: 'impor_sdppi',
//             url: ''
//           },
//           {
//             name: 'pdb_sddpi',
//             url: ''
//           },
//           {
//             name: 'pdb_sektor_komunikasi_informasi',
//             url: ''
//           },
//           {
//             name: 'pdb',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'pertumbuhan_pdb_sektor_komunikasi_informasi',
//             url: ''
//           },
//         ],
//       },
//     ],
//   },
//   {
//     menu: "SDDPI in action",
//     submenu: [
//       {
//         childsubmenu: 'Perpanas',
//         jenis: [
//           {
//             name: 'alat_perangkat',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'isr_smntara',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'mon_sfr_perpanas',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'perangkat_wsbk&perpanas',
//             url: 'kominfo_staging2'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'PON 2021',
//         jenis: [
//           {
//             name: 'data_lokasi_penempatan',
//             url: ''
//           },
//           {
//             name: 'dmp_sfr',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'hasil_mon_sfr',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'jumlah_alat_ukur',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'jumlah_sdppi',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'penggunaan_frekuensi_2',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'perangkat_digunakan',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'perangkat_pertandingan',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'personil_perangkat_open',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'sebaran_penggunaan',
//             url: 'kominfo_staging2'
//           },
//         ],
//       },
//       {
//         childsubmenu: 'wsbk',
//         jenis: [
//           {
//             name: 'alat_perangkat',
//             url: 'kominfo_staging2'
//           },
//           {
//             name: 'penggunaan_frekuensi_2',
//             url: 'kominfo_staging'
//           },
//           {
//             name: 'upt_personil_wsbk_perpanas',
//             url: 'kominfo_staging2'
//           },
//         ],
//       },
//     ],
//   },
// ]