// eslint-disable-next-line
import { Box, Skeleton, Text } from "@chakra-ui/react";
import Tableau from "components/Tableau/Tableau";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetMeQuery } from "redux/services/apiService";
import {
    // useGetMenusQuery,
    useLazyGetMenusQuery,
} from "redux/services/menu";
import slugify from "slugify";

// import { decode } from "utils/hash";

type Props = {};
const DynamicDashboard: React.FC<Props> = (): JSX.Element => {
    const params = useParams();
    // const { data: menus = [] } = useGetMenusQuery();
    const [trigger, {
        data: menus = [],
        isFetching
    }] = useLazyGetMenusQuery();

    const getDesc = (param) => {
        var desc = "";
        menus.some((m) =>
            m.children.some((c) => {
                if (c.name.replaceAll(" ", "-") === param) {
                    desc = c.description;
                }
                return desc;
            })
        );
        return desc;
    };
    const { isLoading } = useGetMeQuery();

    // request each time move route
    useEffect(() => {
        trigger();
    }, [params, trigger]);

    const RenderEmbed = () => {
        // const sourceMenu = decodeURIComponent(decode(params.source));
        const parentMenu = params.parent_menu;
        const subMenu = params.menu;
        const sourceMenu = menus
            .filter((e) => slugify(e.name, { lower: true }) === parentMenu)[0]
            ?.children.filter((e) => slugify(e.name, { lower: true }) === subMenu)[0]?.source;
        if (!sourceMenu) {
            return <></>;
        }
        if (sourceMenu.includes("tableau")) {
            return (
                <>
                    {!isFetching &&
                        <Tableau url={sourceMenu} containerId={params.source} />
                    }
                </>
            );
        } else {
            return (
                <>
                    <iframe
                        src={sourceMenu}
                        style={{ overflow: "hidden", height: "900px", width: "100%" }}
                        title="other"
                        height="900px"
                        width="100%"
                    />
                </>
            );
        }
    };

    return (
        <Skeleton isLoaded={!isLoading}>
            <Box w="100%" backgroundColor="#fff" display="flex" justifyContent="center">
                {params?.source !== "bnVsbA==" ? (
                    <Box w={"full"}>
                        <RenderEmbed />
                        {getDesc(params.menu) && getDesc(params.menu) !== null ? (
                            <>
                                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="2" mb="0">
                                    Deskripsi:
                                </Text>
                                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="0">
                                    {getDesc(params.menu)}
                                </Text>
                            </>
                        ) : null}
                    </Box>
                ) : (
                    <Text fontSize="5xl" fontWeight="bold" color="#C0392B" textAlign="center" mt="20">
                        Coming Soon!
                    </Text>
                )}
            </Box>
        </Skeleton>
    );
};

export default DynamicDashboard;
