import { apiService } from "redux/services/apiService";
// import { ResponseDelete } from "redux/services/types";

// import { Menu, PostMenu } from "./types";

const TYPE = "datalake";
const datasetApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getAllDatalake: build.query({
      query: ({menu, sub, page}) => ({ url: `/menu-staging/${menu}/${sub}?page=${page}` }),
      providesTags: [TYPE],
    }),
    createDatalake: build.mutation({
      query: (data) => ({ url: "/upload-data", method: "post", data: data }),
      invalidatesTags: [TYPE],
    }),
    deleteDatalake: build.mutation({
      query: (data) => ({ url: `/delete`, method: "delete", data: data }),
      invalidatesTags: [TYPE],
    }),
    editDatalake: build.mutation({
      query: (data) => ({ url: `/update`, method: "post", data: data }),
      invalidatesTags: [TYPE],
    }),

  }),
  overrideExisting: false,
});

export const {
  useGetAllDatalakeQuery,
  useCreateDatalakeMutation,
  useDeleteDatalakeMutation,
  useEditDatalakeMutation,
} = datasetApi;
