import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import Cookies from "js-cookie";

const ModalComponent = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [showPopUpChecked, setShowPopUpChecked] = useState(true);

  useEffect(() => {
    const cookiesIsSet = Cookies.get("hide_modal");
    const localStorageIsSet = localStorage.getItem("hide_modal");

    const isSafari = navigator.userAgent.includes("Safari");
    const isChrome = navigator.userAgent.includes("Chrome");

    // do the logic when in safari
    // always set isOpen to false in other brower except safari
    if (!isChrome && isSafari) {
    if (cookiesIsSet || localStorageIsSet) {
      setIsOpen(false);
    }
    } else {
      setIsOpen(false);
    }

    Cookies.set("hide_modal", true);
  }, []);

  const handleShowPopUpCheckBox = (e) => {
    setShowPopUpChecked(e.target.checked);
  };

  const handleClose = () => {
    setIsOpen(false);

    // set local storage
    if (showPopUpChecked) {
      localStorage.setItem("hide_modal", "true");
    } else {
      localStorage.removeItem("hide_modal");
    }
  };

  return (
    <>
      <Modal isCentered onClose={() => {}} isOpen={isOpen} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Third-Party Cookies</ModalHeader>
          <ModalBody>
            <Box>
              The Tableau needs the third-party cookies to be able show the dashboard on this page.
              Please allow third-party cookies on this browser. <br />
              <br />
              The Solutions:
              <br />
              <br />
              This is how to allow third-party cookies on Mac:{" "}
              <b>
                Click Safari - Preferences and click the “Privacy” tab. Make a note about what is
                currently selected. on “Website tracking” and “Cookies and website data”, uncheck
                “Prevent cross-site tracking” and “Block all cookies”.
              </b>
              <br />
              <br />
              or
              <br />
              <br />
              <b>Please use another browser to be able access the dashboard on this website.</b>
            </Box>
            <Checkbox
              size="sm"
              colorScheme="green"
              defaultChecked
              mt="20px"
              onChange={(e) => handleShowPopUpCheckBox(e)}
            >
              <strong>Don't show this pop up again</strong>
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              I understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalComponent;
