import { apiService } from "redux/services/apiService";
import { ResponseDelete } from "redux/services/types";

import { Menu, PostMenu } from "./types";

const TYPE = "menu";
const menuApi = apiService.injectEndpoints({
    endpoints: (build) => ({
        getMenus: build.query<Menu[], void>({
            query: () => ({ url: "/menus" }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.map(({ id }) => ({ type: TYPE, id })),
                        { type: TYPE, id: "list" },
                    ] as any;
                }

                return [{ type: TYPE, id: "list" }];
            },
        }),
        getMenusFlat: build.query<Menu[], void>({
            query: () => ({ url: "/menus/flat" }),
            providesTags: (result) => {
                if (result) {
                    return [
                        ...result.map(({ id }) => ({ type: TYPE, id })),
                        { type: TYPE, id: "list_flat" },
                    ] as any;
                }

                return [{ type: TYPE, id: "list_flat" }];
            },
        }),
        getCountSort: build.query({
            query: () => ({ url: `/countSort` }),
            providesTags: [TYPE],
        }),
        getMenu: build.query<Menu, string>({
            query: (menuId: string) => ({ url: `/menus/${Number(menuId)}` }),
            providesTags: (result) => [{ type: TYPE, id: result.id }],
        }),
        createMenu: build.mutation<Menu, PostMenu>({
            query: (data) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                return {
                    url: "/menus",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    method: "post",
                    data: formData
                }
            },
            // ({ url: "/menus", method: "post", data }),
            invalidatesTags: [
                { type: TYPE, id: "list" },
                { type: TYPE, id: "list_flat" },
            ],
        }),
        editSort: build.mutation({
            query: (data) => ({ url: `/menus/sort`, method: "post", data: data }),
            invalidatesTags: [TYPE],
        }),
        updateMenu: build.mutation<Menu, Pick<Menu, "id"> & PostMenu>({
            query: (data) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                return {
                    url: "/menus/edit",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    method: "post",
                    data: formData
                }
            },
            // ({
            //     url: `/menus/${id}`,
            //     method: "put",
            //     data,
            // }),
            invalidatesTags: (result) => {
                if (result) {
                    return [
                        { type: TYPE, id: "list" },
                        { type: TYPE, id: "list_flat" },
                        { type: TYPE, id: result?.id },
                    ];
                }
                return [];
            },
        }),
        removeMenu: build.mutation<ResponseDelete, number>({
            query: (menuId: number) => ({
                url: `/menus/${menuId}`,
                method: "delete",
            }),
            invalidatesTags: [
                { type: TYPE, id: "list" },
                { type: TYPE, id: "list_flat" },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useEditSortMutation,
    useGetCountSortQuery,
    useGetMenuQuery,
    useGetMenusFlatQuery,
    useGetMenusQuery,
    useLazyGetMenusQuery,
    useCreateMenuMutation,
    useUpdateMenuMutation,
    useRemoveMenuMutation,
} = menuApi;
