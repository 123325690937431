import { apiService } from "redux/services/apiService";
// import { ResponseDelete } from "redux/services/types";

// import { Menu, PostMenu } from "./types";

const TYPE = "dataset";
const datasetApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    getMenusDataset: build.query({
      query: () => ({ url: "/dataset" }),
      providesTags: [TYPE],
    }),
    getAllDatasets: build.query({
      query: () => ({ url: "/file-dataset" }),
      providesTags: [TYPE],
    }),
    getDatasets: build.query({
      query: (menu) => ({ url: `/menu-dataset/${menu}` }),
      providesTags: [TYPE],
    }),
    getDatasetByID: build.query({
      query: (id) => ({ url: `/get-dataset/${id}` }),
      providesTags: [TYPE],
    }),
    createMenuDataset: build.mutation({
      query: (data) => ({ url: "/dataset", method: "post", data }),
      invalidatesTags: [TYPE],
    }),
    createDataset: build.mutation({
      query: (data) => ({ url: "/file-dataset", method: "post", data }),
      invalidatesTags: [TYPE],
    }),
    updateMenuDataset: build.mutation({
      query: () => ({ url: "/dataset/5?name=update dari web baru", method: "put" }),
      invalidatesTags: [TYPE],
    }),
    updateDataset: build.mutation({
      query: ({ id, formData }) => ({
        url: `/file-dataset/${id}?_method=PUT`,
        method: "post",
        data: formData,
      }),
      invalidatesTags: [TYPE],
    }),
    removeMenuDataset: build.mutation({
      query: (datasetId: number) => ({
        url: `/dataset/${datasetId}`,
        method: "delete",
      }),
      invalidatesTags: [TYPE],
    }),
    removeDataset: build.mutation({
      query: (datasetId: number) => ({
        url: `/file-dataset/${datasetId}`,
        method: "delete",
      }),
      invalidatesTags: [TYPE],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMenusDatasetQuery,
  useGetAllDatasetsQuery,
  useGetDatasetsQuery,
  useGetDatasetByIDQuery,
  useCreateMenuDatasetMutation,
  useCreateDatasetMutation,
  useUpdateMenuDatasetMutation,
  useUpdateDatasetMutation,
  useRemoveMenuDatasetMutation,
  useRemoveDatasetMutation,
} = datasetApi;
