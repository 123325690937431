import { extendTheme } from "@chakra-ui/react";

import colors from "./foundations/colors";
import fontSizes from "./foundations/fontSizes";
import styles from "./styles";

/**
 * This file is generated for providing a custom theme to Chakra UI
 *
 * To learn more about custom themes
 * please visit https://chakra-ui.com/docs/getting-started#add-custom-theme-optional
 */

const overrides = {
  ...styles,
  colors,
  fontSizes,
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
    openSans: "Open Sans, sans-serif",
  },
};

const theme = extendTheme(overrides);

export default theme;
