import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Icon, Image,Skeleton, Text } from '@chakra-ui/react';

// import { useGetMeQuery } from "redux/services/apiService";
import { useGetDatasetsQuery, useGetMenusDatasetQuery } from 'redux/services/dataset';

import axios from 'utils/axios';
import { getDateMonthYear } from 'utils/constants';

import iconFile from "../assets/images/sprite-resource-icons.png";

const SubDashboard: React.FC = (): JSX.Element => {
  let [slug, setSlug] = useState<String>("");
  const { t } = useTranslation();


  // const { isLoading } = useGetMeQuery();
  let { data, isLoading, isError } = useGetMenusDatasetQuery("");
  let { data: datasets, isFetching, isError: datasetsError} = useGetDatasetsQuery(slug ? slug : data?.data[0]?.slug, { skip: !data?.data[0]?.slug });

  const handleMenu = (e) => {
    setSlug(e.slug);
  }

  const matchExtention = (extention) => {
    if(extention === "xls" || extention === "xlsx") {
      return "44.5% 0";
    }
    if(extention === "csv") {
      return "53.5% 0";
    }
    if(extention === "pdf") {
      return "17.75% 0";
    }
    return "0% 0";
  }

  const downloadFile = (file) => {
    axios({
      url: `/download/${file.id}`,
      method: "GET",
      responseType: "blob",
    }).then((res)=>{
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.file);
      document.body.appendChild(link);
      link.click();
    })
  }

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box display="flex" justifyContent="center" px="16" mt="4" gap="15px" fontFamily="calibri">
          <Box w="100%" maxW="300px" backgroundColor="white" border="1px" borderColor="blackAlpha.300" borderRadius="10px">
            <Text bgColor="#EDEFF4" textAlign="center" m="auto" p="5px" borderRadius="10px 10px 0px 0px" fontWeight="bold" fontSize="2xl">{t("directory")}</Text>
            <Box>
              {
                isError ? <Text py="45px" textAlign="center">Menu Tidak Ditemukan</Text> :
                data?.data.map((e)=>(
                  <Text backgroundColor="white" py="10px" px="15px" m="0px" border="1px" borderColor="blackAlpha.100" _hover={{cursor: "pointer"}} onClick={()=>{handleMenu(e)}}>{e.name}</Text>
                ))
              }
            </Box>
          </Box>

          <Box w="100%" backgroundColor="white" p="20px">
            <Text fontSize="3xl" fontWeight="bold" color="blackAlpha.800">{t("list_dataset")}</Text>
            <Box>
              {
                datasetsError ? <Text>Dataset Tidak Ditemukan</Text> :
                datasets?.data.map((e, i)=>(
                  <Skeleton isLoaded={!isFetching} key={`${e.name}${i}`}>
                    <Box backgroundColor="#EDEFF4" p="10px" borderRadius="5px" mb="10px">
                      <Text fontWeight="bold" color="blue.500" mb="0px" fontSize="xl">{e.name}</Text>
                      <Text fontWeight="normal" color="blackAlpha.600" mb="10px">{e.desc !== "null" ? e.desc : ""}</Text>
                      <Box display="flex" mb="20px">
                        <Image draggable={false} src={iconFile} alt="icon" _hover={{cursor: "pointer"}} style={{ width: "58px", height: "62px", transform: "scale(0.65)", objectFit: "none", objectPosition: `${matchExtention(e.file.split(".")[1])}`}} onClick={()=>{downloadFile(e)}} />
                      </Box>
                      <Box display="flex" alignItems="center" gap="10px" color="blackAlpha.600">
                        <Icon><CalendarIcon /></Icon>
                        <Text mb="0px">{getDateMonthYear(e.created_at)}</Text>
                      </Box>
                    </Box>
                  </Skeleton>
                ))
              }
            </Box>
          </Box>
      </Box>
    </Skeleton>
  )
}

export default SubDashboard;