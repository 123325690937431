import React, { lazy, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Box, Flex, Text } from "@chakra-ui/react";

import { subMenuName } from "redux/slices/menu";
import { useAppSelector } from "redux/store";
const TopMenu = lazy(() => import("./MenuBar/topMenu"));
const Header = lazy(() => import("./Header/Header"));

// const NavBar = lazy(() => import("components/NavBar/Navbar"));
//

const Layout: React.FC = () => {
    const subName = useAppSelector(subMenuName);
    const location = useLocation();

    return (
        <Flex direction="column" minH="100vh" bgColor="#EDEFF4">
            <Suspense fallback={<div>Loading...</div>}>
                <Header />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <Flex
                    position="sticky"
                    top="0"
                    left="0"
                    align="center"
                    justify="between"
                    bgGradient="linear(90deg, #24255D 0%, #00ADEF 100%)"
                    zIndex="10000"
                >
                    <TopMenu />
                </Flex>
                {/* <Flex gap="20px" height="100%" position="relative"> */}
                {/* <Box position="absolute" height="100%" zIndex="1000">
            <NavBar />
          </Box> */}
                <Box w="100%" p="0px 0px 0px 0px">
                    <Text
                        position="relative"
                        fontWeight="bold"
                        fontSize="17px"
                        fontFamily="Poppins, sans-serif"
                        color="#1A202C"
                        w="100%"
                        bgColor="white"
                        p="10px 10px 10px 20px"
                        margin="0"
                    >
                        {location.pathname === "/" ? "" : subName}
                    </Text>
                    <Outlet />
                </Box>
            </Suspense>
            {/* <div style={{ height: "100vh" }} /> */}
        </Flex>
    );
};

export default Layout;
