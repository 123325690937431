// eslint-disable-next-line
import React, {
    useEffect,
    useState,
    // useCallback 
} from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Image,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
} from "@chakra-ui/react";

import {
    useCreateDatalakeMutation,
    useDeleteDatalakeMutation,
    useEditDatalakeMutation,
    useGetAllDatalakeQuery,
} from "redux/services/datalake";

import Papa from 'papaparse';

import { MENUS_DATALAKE } from "utils/datalakesMenus";

import iconFile from "../assets/images/sprite-resource-icons.png";



const Datalake: React.FC = (): JSX.Element => {
    const toast = useToast()
    const { t } = useTranslation()
    const [deleteItem] = useDeleteDatalakeMutation()
    const [editItem] = useEditDatalakeMutation()
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalEdit, setisOpenModalEdit] = useState(false);
    const handleDelete = (index) => {
        setIsOpenModalDelete(true);
        setIndexDelete(index)
    };

    const handleCloseModal = () => {
        setIsOpenModalDelete(false);
        setisOpenModalEdit(false)
    };
    const [dataArray, setDataArray] = useState([])
    const handleEdit = (index) => {
        setisOpenModalEdit(true)
        setEditIndex(index)
        const dataObject = data?.data[index]
        const excludesKey = ["created_at", "updated_at", "id"]
        const dataArr = []
        const outputFilter = Object.keys(dataObject)
            .reduce((obj, key) => {
                if (!excludesKey.includes(key)) {
                    obj[key] = dataObject[key];
                }
                return obj;
            }, {});
        for (let prop in outputFilter) {
            dataArr.push(outputFilter[prop])
        }
        const output = data?.header.map(key => outputFilter[key])
        setDataArray(output.filter(item => item !== undefined))
    }

    let [selectedMenu, setSelectedMenu] = useState({
        menu: "kominfo_ditops",
        sub: "bts_ipfr",
        indexMenu: 0,
        indexSub: 0,
        page: 1
    });
    const { data, isFetching, isError } = useGetAllDatalakeQuery(selectedMenu);
    const dataFilter = data?.header.filter(item => item !== "created_at" && item !== "updated_at")

    const columnFormated = [
        "tgl_masuk",
        "tgl_awal",
        "tgl_akhir",
        "tgl_ijin",
        "tgl",
        "tanggal_lahir",
        "tgl_penetapan",
        "tgl_jatah",
        "tgl_disetujui",
        "tgl_lahir",
        "tgl_spl",
        "tgl_libur",
        "tgl_mulai",
        "tgl_ijazah",
        "tgl_nota",
        "tgl_sk_cpns",
        "tgl_sk",
        'tgl_msk',
        'tgl_nilai',
        'tgl_selesai',
        'tgl_sttp',
        'tgl_sklain',
        'tgl_berhenti',
        'tgl_pengundangan',
        'tanggal_sertifikat',
        'tanggal',
        'tgl_biu',
        'tgl_sertifikat',
        "tgl_luncur",
        "tgl_luncur_sp",
        "tgl_luncur_sp2"
    ];
    const defaultValue = "xxxx";
    const dateValue = "yyyy-mm-dd";
    const yearValue = "yyyy"
    const timestampValue = "yyyy-mm-dd hh:mm:ss"

    const resultDataSample = [];

    for (let i = 0; i < dataFilter?.length; i++) {
        if (dataFilter[i] === "d_rcv") {
            resultDataSample.push(timestampValue);
        }
        else if (dataFilter[i] === "tahun") {
            resultDataSample.push(yearValue);
        }
        else if (columnFormated.includes(dataFilter[i])) {
            resultDataSample.push(dateValue);
        } else {
            resultDataSample.push(defaultValue);
        }
    }

    const [pageCount, setPageCount] = useState(1)

    const handleMenu = ({ menu, sub, indexMenu, indexSub }) => {
        setSelectedMenu({ menu, sub, indexMenu, indexSub, page: 1 });
    }
    useEffect(() => {
        setPageCount(data?.last_page)
    }, [data])

    const handlePageClick = async (data: any) => {
        setSelectedMenu({ menu: selectedMenu.menu, sub: selectedMenu.sub, indexMenu: selectedMenu.indexMenu, indexSub: selectedMenu.indexSub, page: data.selected + 1 })
    };
    const [createDatalake, { isLoading: isUpdating }] = useCreateDatalakeMutation();
    const handleDownloadTemplate = () => {
        const dataDownload = { fields: [...data?.header.filter(item => item !== "created_at" && item !== "updated_at")], data: resultDataSample }
        const csvData = Papa.unparse(dataDownload, { newline: '\n' });
        console.log(csvData, "csvData")
        const blob = new Blob([csvData], { type: 'text/csv' })
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `template_${selectedMenu.sub}_${selectedMenu.menu}.csv`;
        alink.click();
    }

    const handleImportFile = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            header: false,
            complete: (results) => {
                let data = [];
                if (results.data[0][0].search(";") !== -1) {
                    // eslint-disable-next-line
                    results.data.map((e) => {
                        data.push(e[0].split(","));
                    })
                    createDatalake({ "name": selectedMenu.menu, "jenis": selectedMenu.sub, data: data });
                    return
                }
                // eslint-disable-next-line
                results.data.map((e) => {
                    data.push(e);
                })
                createDatalake({ "name": selectedMenu.menu, "jenis": selectedMenu.sub, data: data });
            },
        });
        e.target.value = ""
    }

    const [indexDelete, setIndexDelete] = useState(null)
    const handleDeleteItem = async () => {
        let items = {
            name: selectedMenu.menu,
            jenis: selectedMenu.sub,
            data: data?.data[indexDelete]
        }
        await deleteItem(items);
        toast({
            title: t("Delete Success"),
            isClosable: true,
            status: "success",
            duration: 3000,
        });
        setIsOpenModalDelete(false)
    }
    const [dataEdit, setDataEdit] = useState({})
    columnFormated.forEach(key => {
        if (key in dataEdit) {
            let value = dataEdit[key];
            if (value.includes("undefined")) {
                dataEdit[key] = "";
            } else if (!value.includes('-')) {
                dataEdit[key] = value
            } else {
                let [year, month, day] = value.split("-");
                if (day === undefined) day = "";
                if (month === undefined) month = "";
                if (year === undefined) year = "";
                dataEdit[key] = `${day}/${month}/${year}`.replace(/^\//, '');
            }
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataEdit((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const date = new Date();
    const offset = 7 * 60 * 60 * 1000;
    const dateString = new Date(date.getTime() + offset).toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const [editIndex, setEditIndex] = useState(null)
    const handleEditItem = async () => {
        if (data?.header.includes("updated_at")) {
            dataEdit["updated_at"] = dateString
        }
        const dataBefore = data?.data[editIndex]
        let items = {
            name: selectedMenu.menu,
            jenis: selectedMenu.sub,
            data: dataBefore,
            update: dataEdit
        }
        await editItem(items);
        toast({
            title: t("Edit Success"),
            isClosable: true,
            status: "success",
            duration: 3000,
        });
        setisOpenModalEdit(false)
        setDataEdit({})
    }

    return (
        <Box display="flex" gap="50px" fontFamily="Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif">
            {/* menubar */}
            <Box w="500px" overflow="auto" backgroundColor="white" style={{ "height": "calc(100vh)" }} position="sticky" top="0px">
                <Accordion allowToggle>
                    {
                        MENUS_DATALAKE.map((menu, indexMenu) => (
                            <AccordionItem>
                                <h2>
                                    <AccordionButton _expanded={{ bg: '#2B6CB0', color: 'white' }}>
                                        <Box flex='1' textAlign='left'>
                                            {menu.menu.split("_").join(" ")}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                {
                                    menu.submenu.map((sub, indexSub) => (
                                        <AccordionPanel pb={4} p="0px">
                                            <Button colorScheme='gray' w="100%" justifyContent="flex-start" borderRadius="0px" onClick={() => { handleMenu({ menu: menu.menu, sub: sub.name, indexMenu, indexSub }) }}>{sub.name.split("_").join(" ")}</Button>
                                        </AccordionPanel>
                                    ))
                                }
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </Box>
            {/* menubar */}
            <Box w="100%" p="50px 50px 150px 0px" overflow="auto">
                <Box display="flex" justifyContent="space-between" mb="20px" p="20px">
                    <Box mb="20px">
                        <Text mb="0px" fontSize="4xl" textTransform="capitalize" fontWeight="bold">{selectedMenu.sub.split("_").join(" ")}</Text>
                        <Text>{selectedMenu.menu}</Text>
                    </Box>
                    {/* <Box mb="20px" display="flex" flexDirection="column" gap="50px"> */}
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Text mb="0px" fontWeight="bold">Download Template Di Sini</Text>
                        <Tooltip label='Download template'>
                            <Image draggable={false} src={iconFile} alt="icon" _hover={{ cursor: "pointer" }} style={{ width: "58px", height: "62px", transform: "scale(0.65)", objectFit: "none", objectPosition: "53.5% 0" }} onClick={() => { handleDownloadTemplate() }} />
                        </Tooltip>
                    </Box>

                    <Box>
                        <Text fontWeight="bold">Import Data Di Sini</Text>
                        {
                            isUpdating && <CircularProgress isIndeterminate color='#0792D2' />
                        }
                        <input type="file" onChange={(e) => { handleImportFile(e) }} />
                    </Box>
                    {/* </Box> */}
                </Box>
                <Box backgroundColor="white" w="100%" p="20px" borderRadius="10px">
                    {
                        isFetching ?
                            <Stack>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                            :
                            <TableContainer>
                                {isError ? <Text>Data is not found</Text> : (
                                    <Table variant='simple'>

                                        <Thead>
                                            <Tr fontWeight="bold">
                                                {
                                                    data?.header.map((e) => (
                                                        <Th>{e}</Th>
                                                    ))
                                                }
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                data?.data?.map((each, index) => {
                                                    return (
                                                        <Tr>
                                                            {
                                                                data?.header.map((header) => {
                                                                    // console.log(each[header], 'each[header]')
                                                                    return (
                                                                        <Td>{each[header]}</Td>
                                                                    )
                                                                })
                                                            }
                                                            <Menu>
                                                                <MenuButton as={IconButton} icon={
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-vertical">
                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                        <circle cx="12" cy="5" r="1"></circle>
                                                                        <circle cx="12" cy="19" r="1"></circle>
                                                                    </svg>
                                                                } variant="ghost" />
                                                                <MenuList>
                                                                    <MenuItem onClick={() => handleEdit(index)}>Edit</MenuItem>
                                                                    <MenuItem onClick={() => handleDelete(index)}>Hapus</MenuItem>
                                                                </MenuList>
                                                            </Menu>
                                                        </Tr>
                                                    )
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                )}
                            </TableContainer>
                    }
                </Box>
                {data?.last_page ? <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center mt-4"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
                    : null}
            </Box>
            <Modal isOpen={isOpenModalDelete} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Peringatan!</ModalHeader>
                    <ModalBody>Apakah Anda yakin ingin menghapus data ini?</ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleCloseModal}>
                            Tidak
                        </Button>
                        <Button colorScheme="green" onClick={handleDeleteItem}>
                            Ya
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isOpenModalEdit} onClose={handleCloseModal} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Data</ModalHeader>
                    <ModalBody>
                        <Table>
                            <Tbody>
                                {data?.header.filter(item => item !== "created_at" && item !== "updated_at" && item.toLowerCase() !== "id").map((key, index) => {
                                    return (
                                        <Tr>
                                            <Td>{key}</Td>
                                            <Td>
                                                <Input
                                                    name={key}
                                                    defaultValue={dataArray[index]}
                                                    onChange={handleChange}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleEditItem}>
                            Simpan
                        </Button>
                        <Button variant="ghost" onClick={handleCloseModal}>
                            Batal
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
};

export default Datalake;
